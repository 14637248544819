import React, { createContext, useState, useEffect, useContext } from 'react';
import fetch from 'isomorphic-fetch';
import { buildClient } from 'shopify-buy';
import { gql, useMutation, useQuery } from '@apollo/client'
import { navigate } from 'gatsby';
import { disableScroll, enableScroll } from '../utils/helpers';

const client = buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)

const getLocalStorage = (value) => {
  try {
    return JSON.parse(localStorage.getItem(value))
  } catch (e) {
    return '';
  }
}

const defaultValues = {
  loading: false,
  cartIsOpen: false,
  menuIsOpen: false,
  searchIsOpen: false,
  scrollDisabled: false,
  lightboxOpen: false,
  viewedProducts: [],
  searchTerm: '',
  toggleWish: () => { },
  addVariantToCart: () => {},
  addVariantsToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  client,
  checkout: {
    lineItems: [],
  },
}

export const StoreContext = createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`

export const StoreProvider = ({ children }) => {
  const [wishList, setWishList] = useState(getLocalStorage('wishlist') || [])
  const [checkout, setCheckout] = useState(defaultValues.checkout)
  const [loading, setLoading] = useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = useState(false)
  const [cartIsOpen, setCartIsOpen] = useState(defaultValues.cartIsOpen);
  const [menuIsOpen, setMenuIsOpen] = useState(defaultValues.menuIsOpen);
  const [searchIsOpen, setSearchIsOpen] = useState(defaultValues.searchIsOpen);
  const [customerAccessToken, setCustomerAccessToken] = useState({ accessToken: null });
  const [customerData, setCustomerData] = useState(getLocalStorage('customerData'));
  const [lightboxOpen, setLightboxOpen] = useState(defaultValues.lightboxOpen);
  const [lightboxContent, setLightboxContent] = useState(null);
  const [currentMenuItem, setCurrentMenuItem] = useState(null);
  const [viewedProducts, setViewedProducts] = useState(defaultValues.viewedProducts);
  const [searchTerm, setSearchTerm] = useState(defaultValues.searchTerm);

  const toggleCartIsOpen = () => {
    if(cartIsOpen) {
      setTimeout(() => setDidJustAddToCart(false), 200)
    }
    setCartIsOpen(prevState => !prevState);
  }

  const toggleMenuIsOpen = () => {
    setMenuIsOpen(prevState => !prevState);
  }

  const closeMenu = () => {
    setMenuIsOpen(false);
  }

  const openMenu = () => {
    setMenuIsOpen(true);
  }

  const openSearch = () => {
    setSearchIsOpen(true);
  }

  const closeSearch = () => {
    setSearchIsOpen(false);
  }

  useEffect(() => {
    if(menuIsOpen) {
      disableScroll()
    } else {
      enableScroll();
    }
  }, [menuIsOpen])

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
  }

  const toggleWish = (product) => {
    let list = wishList;
    if (list.indexOf(product) === -1) {
      list.push(product)
      setWishList(list);
    } else {
      list = list.filter(item => item !== product)
      setWishList(list)
    }
    isBrowser && localStorage.setItem('wishlist', JSON.stringify(list))
  }

  useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          )
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null)
        }
      }

      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
    }

    initializeCheckout()
  }, [])

  const addVariantToCart = (variantId, quantity, openCart = true) => {
    setLoading(true)

    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ]

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
        setDidJustAddToCart(true)
        window?.dataLayer?.push({
          event: 'add_to_cart',
          checkoutId: checkoutID,
          title: res.lineItems.find(item => item.variant.id === variantId).title,
          variantId: variantId,
          quantity: quantity,
          price: res.lineItems.find(item => item.variant.id === variantId).variant.priceV2.amount,
        })
        if(openCart) { setCartIsOpen(true) }
        // setTimeout(() => setDidJustAddToCart(false), 3000)
      })
  }

  const addVariantsToCart = (variantIds, openCart = true) => {
    setLoading(true)

    const checkoutID = checkout.id

    const lineItemsToUpdate = variantIds.map(id => {
      return {
        variantId: id,
        quantity: 1,
      }
    })

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
        setDidJustAddToCart(true)
        variantIds.forEach(id => {
          window?.dataLayer?.push({
            event: 'add_to_cart',
            checkoutId: checkoutID,
            title: res.lineItems.find(item => item.variant.id === id).title,
            variantId: id,
            quantity: 1,
            price: res.lineItems.find(item => item.variant.id === id).variant.priceV2.amount,
          })
        })
        if(openCart) { setCartIsOpen(true) }
        // setTimeout(() => setDidJustAddToCart(false), 3000)
      })
  }

  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true)

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then((res) => {
        setCheckout(res)
        setLoading(false)
      })
  }

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
      })
  }

  const addViewedProduct = (product) => {
    if(!viewedProducts.map(prod => prod.handle).includes(product.handle)) {
      setViewedProducts(prevState => {
        if(prevState.length >= 10) { prevState.pop() }
        return [product, ...prevState];
      });
    }
  }

  useEffect(() => {
    if(viewedProducts.length > 0) {
      localStorage.setItem('viewedProducts', JSON.stringify(viewedProducts));
    }
  }, [viewedProducts])

  useEffect(() => {
    const storedViewedProducts = localStorage.getItem('viewedProducts');
    if(storedViewedProducts) {
      setViewedProducts(JSON.parse(storedViewedProducts));
    }
  }, [])

  const addSearchTerm = (term) => {
    setSearchTerm(term);
  }

  useEffect(() => {
    const getSearchTerm = localStorage.getItem('blog_terms');
    if(getSearchTerm) {
      setSearchTerm(searchTerm);
    }
  }, [searchTerm])

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        addVariantsToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
        toggleWish,
        wishList,
        cartIsOpen,
        menuIsOpen,
        searchIsOpen,
        setSearchIsOpen,
        toggleCartIsOpen,
        toggleMenuIsOpen,
        closeMenu,
        openMenu,
        closeSearch,
        openSearch,
        didJustAddToCart,
        disableScroll,
        enableScroll,
        customerAccessToken,
        setCustomerAccessToken,
        customerData,
        setCustomerData,
        lightboxOpen,
        setLightboxOpen,
        lightboxContent,
        setLightboxContent,
        currentMenuItem,
        setCurrentMenuItem,
        viewedProducts,
        addViewedProduct,
        searchTerm,
        setSearchTerm,
        addSearchTerm,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

const CUSTOMER_INFO = gql`
  query($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      email
      firstName
      lastName
    }
  }
`

const CUSTOMER_SIGNOUT = gql`
  mutation customerAccessTokenDelete($customerAccessToken: String!) {
    customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
      deletedAccessToken
      deletedCustomerAccessTokenId
      userErrors {
        field
        message
      }
    }
  }
`

export const useAccount = () => {
  const { customerAccessToken, setCustomerAccessToken, customerData, setCustomerData } = useContext(StoreContext);

  const { customerInfo } = useQuery(CUSTOMER_INFO, {
    skip: !customerAccessToken || !customerAccessToken.accessToken || customerData,
    variables: { customerAccessToken: customerAccessToken?.accessToken },
  })

  useEffect(() => {
    if(customerInfo?.data?.customer) {
      localStorage.setItem('customerData', JSON.stringify(customerInfo.data.customer))
      setCustomerData(customerInfo.data.customer)
    }
  }, [customerInfo, setCustomerData])

  const setToken = (value) => {
    isBrowser && localStorage.setItem('customerAccessToken', JSON.stringify(value));
    setCustomerAccessToken(value);
  }

  const [customerSignoutFunction] = useMutation(CUSTOMER_SIGNOUT, {
    variables: customerAccessToken ? { customerAccessToken:  customerAccessToken.accessToken} : null,
    onCompleted: (data) => {
      if (data && data.customerAccessTokenDelete.userErrors.length) {
        console.error('Logout error: ', data.customerAccessTokenDelete.userErrors)
        return false;
      }
      setToken({ customerAccessToken: '' })
      setCustomerData('')
      if(isBrowser) { localStorage.removeItem('customerData') }
      navigate('/account/login')
    },
  })

  const userIsAuthenticated = () => {
    if(typeof window === 'undefined') { return false }

    /**
     * Sometimes it takes a second for customerAccessToken to be set, which
     * causes weird problems when we're trying to check if a user is
     * authenticated on page load. So here we are using a fallback that
     * checks localStorage if the access token state var hasn't been set yet.
     */
    const accessToken = customerAccessToken?.accessToken ? customerAccessToken : getLocalStorage('customerAccessToken');

    if(
      accessToken &&
      accessToken.expiresAt &&
      accessToken.expiresAt > new Date().toISOString()
    ) {
      return true;
    } else {
      signout();
      return false;
    }
  }

  const signout = () => {
    if(customerAccessToken.accessToken && isBrowser) {
      customerSignoutFunction();
    }
  }

  useEffect(() => {
    async function getAccount() {
      if (customerAccessToken.accessToken) return;

      if (!isBrowser) {
        setCustomerAccessToken({ accessToken: null });
      }

      const token = getLocalStorage('customerAccessToken');
      if (token) {
        setCustomerAccessToken(token);
      }
    }

    getAccount();
  }, [customerAccessToken?.accessToken, setCustomerAccessToken]);

  return {
    setToken,
    customerData,
    setCustomerData,
    signout,
    userIsAuthenticated,
  }
}
